import React from "react"
import {
  Row,
  Card,
  Button,
  Container,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Popover,
} from "react-bootstrap"
import "./Products.css"
import { FaCheck, FaBan } from "react-icons/fa"

import { RouteComponentProps } from "@reach/router"
import { navigate } from "gatsby"
import { bundles, features } from "../utils/product_description"

// Tell webpack this JS file uses this image
const Feature = ({ children, available, tooltip }) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{"Info"}</Popover.Title>
      <Popover.Content>{tooltip}</Popover.Content>
    </Popover>
  )
  const t = <Tooltip id="1">{tooltip}</Tooltip>
  return (
    <React.Fragment>
      <OverlayTrigger placement="top" overlay={popover}>
        <p className="feature">
          {available ? (
            <span className="feature-icon available">
              <FaCheck style={{ color: "green" }} />
            </span>
          ) : (
            <span className="feature-icon">
              <FaBan className="feature-icon" style={{ color: "red" }} />
            </span>
          )}
          {available ? (
            <span style={{ color: "black" }}>{children}</span>
          ) : (
            <span style={{ color: "lightgrey" }}>{children}</span>
          )}
        </p>
      </OverlayTrigger>
    </React.Fragment>
  )
}

const Features = ({ bundle }: { bundle: string }) => {
  const x = bundle.toLowerCase()
  const X = bundle.toUpperCase()
  return (
    <>
      {" "}
      {features.map(f =>
        f.package.indexOf(X) >= 0 ? (
          <Feature available={true} tooltip={f.description}>
            {f.title}{" "}
          </Feature>
        ) : f.package.indexOf(x) >= 0 ? (
          <Feature available={false} tooltip={f.description}>
            {f.title}
          </Feature>
        ) : (
          <></>
        )
      )}
    </>
  )
}

const Price = ({ price, currency, period }) => (
  <React.Fragment>
    <span className="price">{price}</span>
    <span className="currency">{currency}</span>
    <span className="period">{period}</span>
  </React.Fragment>
)
const MyCard = ({ bundle }) => {
  const b = bundles[bundle]
  return (
    <Card className="product-box">
      <Card.Header>
        {b.logo && b.logo}
        <Card.Title>{b.title}</Card.Title>
        <Card.Subtitle>{b.description}</Card.Subtitle>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          <Features bundle={bundle}></Features>
        </Card.Text>
        <Card.Text>
          {b.price && (
            <Price
              price={b.price[0]}
              currency={b.price[1]}
              period={b.price[2]}
            />
          )}
        </Card.Text>
        {b.plan && (
          <Form action="https://node-opcua.memberful.com/checkout" method="get">
            <Form.Control type="hidden" name="plan" value={b.plan} />
            {/* <Form.Control
                  type="number"
                  name="price"
                  placeholder="Choose what you pay"
                  required
                  min="200.00"
                  step="0.01"
                /> */}
            <Button variant="primary" type="submit">
              Subscribe Now
            </Button>
          </Form>
        )}
        {b.button && (
          <Button
            variant="primary"
            type="submit"
            onClick={() => navigate(b.button?.url! || "")}
          >
            {b.button.text}
          </Button>
        )}
      </Card.Body>
    </Card>
  )
}
export const Products = (props: RouteComponentProps) => {
  return (
    <Container>
      <Row>
        <Col lg="3" md="6">
          <MyCard bundle="A"></MyCard>
        </Col>
        <Col lg="3" md="6">
          <MyCard bundle="B"></MyCard>
        </Col>
        <Col lg="3" md="6">
          <MyCard bundle="C"></MyCard>
        </Col>
        <Col lg="3" md="6">
          <MyCard bundle="D"></MyCard>
        </Col>
      </Row>
    </Container>
  )
}
