import { isBrowser } from "./auth"

declare let setup: any
isBrowser() &&
  ((window as any).MemberfulOptions = {
    site: "https://node-opcua.memberful.com",
  })
export function installMemberfulHook() {
  if (!isBrowser()) {
    return
  }
  var s = document.createElement("script")

  s.type = "text/javascript"
  s.async = true
  s.src = "https://d35xxde4fgg0cx.cloudfront.net/assets/embedded.js"

  const setup = function () {
    ;(window as any).MemberfulEmbedded.setup()
  }

  s.addEventListener("load", setup, false)
  ;(
    document.getElementsByTagName("head")[0] ||
    document.getElementsByTagName("body")[0]
  ).appendChild(s)

  console.log("installMemberfulHook = done")
}

installMemberfulHook()
